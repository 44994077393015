import { useRuntimeConfig } from '#app';
import { useRouter } from 'vue-router';

const config = useRuntimeConfig();
const BASE_PATH = config.public.api_url
const router = useRouter();

export const useAxiosGet = async (path) => {

    try {
        const isPreviewing = sessionStorage.getItem('isPreview')
        const token = isPreviewing ? sessionStorage.getItem('token') : localStorage.getItem('token')
        const res = await $fetch(`${BASE_PATH}/${path}`, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        if (error.statusCode === 401 || error.statusCode === 500) {
            localStorage.clear()
            sessionStorage.clear()
            router.push('/login')
        }
        console.log('Error DATA GET :', error.data)
        throw error.data
    }

}

export const useAxiosPost = async (path, request_body) => {
    try {
        const isPreviewing = sessionStorage.getItem('isPreview')
        const token = isPreviewing ? sessionStorage.getItem('token') : localStorage.getItem('token')
        const res = await $fetch(`${BASE_PATH}/${path}`, {
            method: 'POST',
            body: JSON.stringify(request_body),
            headers: {
                authorization: `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        if (error.statusCode === 401 || error.statusCode === 500) {
            localStorage.clear()
            sessionStorage.clear()
            router.push('/login')
        }
        console.log('Error DATA POST :', error.data)
        throw error.data
    }
}

export const useAxiosPatch = async (path, request_body) => {
    try {
        const isPreviewing = sessionStorage.getItem('isPreview')
        const token = isPreviewing ? sessionStorage.getItem('token') : localStorage.getItem('token')
        const res = await $fetch(`${BASE_PATH}/${path}`, {
            method: 'PATCH',
            body: JSON.stringify(request_body),
            headers: {
                authorization: `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        if (error.statusCode === 401 || error.statusCode === 500) {
            localStorage.clear()
            sessionStorage.clear()
            router.push('/login')
        }
        console.log('Error DATA PATCH :', error.data)
        throw error.data
    }
}

export const useAxiosDelete = async (path) => {
    try {
        const isPreviewing = sessionStorage.getItem('isPreview')
        const token = isPreviewing ? sessionStorage.getItem('token') : localStorage.getItem('token')
        const res = await $fetch(`${BASE_PATH}/${path}`, {
            method: 'DELETE',
            body: JSON.stringify({}),
            headers: {
                authorization: `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        if (error.statusCode === 401 || error.statusCode === 500) {
            localStorage.clear()
            sessionStorage.clear()
            router.push('/login')
        }
        console.log('Error DATA DELETE:', error.data)
        throw error.data
    }
}

export const useAxiosFormDataPost = async (path, formData) => {

    try {
        const isPreviewing = sessionStorage.getItem('isPreview')
        const token = isPreviewing ? sessionStorage.getItem('token') : localStorage.getItem('token')
        const res = await $fetch(`${BASE_PATH}/${path}`, {
            method: 'POST',
            body: formData,
            headers: {
                authorization: `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        if (error.statusCode === 401 || error.statusCode === 500) {
            localStorage.clear()
            sessionStorage.clear()
            router.push('/login')
        }
        console.log('Error DATA FORMDATA:', error.data)
        throw error.data
    }


}

// Only for screenshot monitor

export const useAxiosForSSM = async (api_key, path, request_body) => {

    try {
        const { data } = await $fetch(`https://screenshotmonitor.com/api/v2/${path}`, {
            method: 'POST',
            body: JSON.stringify(request_body),
            headers: {
                "X-SSM-Token": api_key,
                "Content-type": "application/json",
            },
        })
        return data
    } catch (error) {
        console.log('Error DATA:', error.data)
        throw error.data
    }


}
