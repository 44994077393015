import { defineStore } from "pinia";

export const useErrorStore = defineStore('error', {
    state: () => {
        return {
            error: {
                isShow: false,
                message: '',
                type: ''
            }
        }
    },
    actions: {
        showError(message, isShow, type) {
            this.error.message = message,
                this.error.isShow = isShow,
                this.error.type = type
        },
        resetErrorStore(){
            this.error.isShow=  false
            this.error.message= ''
             this.error.type= ''
        }
    },
})